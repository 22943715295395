import React from "react";
import { Link } from "react-router-dom";
import { BtnDownload } from "../btnDownload";

import "./footer.scss";

import LogoOficial from "../../assets/imgs/logo-jc.png";
import LogoIso from "../../assets/imgs/ISO.png";
import { useTranslation } from "react-i18next";


export const Footer = () => {
  const {t}=useTranslation()
  const anioActual = () => {
    var fecha = new Date().getFullYear();
    return fecha;
  };
  return (
    <footer className="wrapper_footer">
      <div className="drawer_mnsj">
        <div className="container-lg">
          <div className="row">
            <div className="row align-items-center">
              <div className="col-md-8 mb-3">
                <div className="mnsj_info">
                  <h3 className="ttls ttl_section mb-4">
                    {t('¿Tienes una idea para tu evento?')}
                  </h3>
                  <p className="paragraph lg m-0">
                  {t('No te preocupes, nosotros nos encargamos de todo.')}
                  </p>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="box_options_btns">
                  <BtnDownload colors="in-color" />

                  <Link to="/contacto" className="btn btn-primary ">
                    <span>
                      <i className="fa-solid fa-phone pt-1"></i>
                    </span>
                    <span>{t('¡HABLEMOS!')}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="drawer_footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 info_foot_msnj">
              <div className="">
                <div className="lgo">
                  <img src={LogoOficial} alt="JC Innovation" />
                </div>
                <h2 className="mt-1">
                {t('Seamos parte del mismo equipo y hagámoslo posible.')}
                </h2>
                <div className="sec_links_socials">
                  <div className="socials">
                    <a
                      target="_blank"
                      title="Facebook"
                      rel="nofollow noopener noreferrer"
                      href="https://www.facebook.com/jcinnovationoficial"
                    >
                      <i className="fa-brands fa-facebook-square"></i>
                    </a>
                    <a
                      target="_blank"
                      title="Instagram"
                      rel="nofollow noopener noreferrer"
                      href="https://www.instagram.com/jcinnovationoficial/"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a
                      target="_blank"
                      title="Twitter"
                      rel="nofollow noopener noreferrer"
                      href="https://twitter.com/jc_innovation"
                    >
                      <i className="fa-brands fa-x-twitter"></i>
                    </a>
                    <a
                      target="_blank"
                      title="Linkedin"
                      rel="nofollow noopener noreferrer"
                      href="https://www.linkedin.com/company/jc-innovation/"
                    >
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a
                      target="_blank"
                      title="Linkedin"
                      rel="nofollow noopener noreferrer"
                      href="https://www.google.com/maps/place/World+Trade+Center+Mexico+City/@19.3936599,-99.1771628,17z/data=!3m1!5s0x85d1ff71986b261b:0x9959045f882ded13!4m10!1m2!2m1!1sWTC.+Piso12+oficina+19!3m6!1s0x85d1ff718f4416e1:0x6e35698f0852629c!8m2!3d19.3936599!4d-99.1745879!15sChdXVEMuIFBpc28gMTIgb2ZpY2luYSAxOZIBD2J1c2luZXNzX2NlbnRlcuABAA!16zL20vMDRraHdr"
                    >
                      <i class="fa fa-map-marker" aria-hidden="true"></i>
                    </a>
                    {/* <a
                      target="_blank"
                      title="Youtube"
                      rel="nofollow noopener noreferrer"
                      href="https://www.youtube.com/channel/UCx0MHsRF0UzEW14iUOS4vOQ"
                    >
                      <i className="fa-brands fa-youtube"></i>
                    </a> */}
                  </div>
                  <img className="img_iso" src={LogoIso} alt="ISO Certified" />
                </div>
              </div>
            </div>
            <div className="col-md-6 info_foot_contact">
              <h2 className="mt-1"> {t('Contacta un asesor')}</h2>
              <div className="">
                <p className="paragraph sm mbtm">
                {t('Estamos seguros que podemos ayudarte en los proyectos que tienes en mente.')} 
                </p>
                <p className="box_p_foot paragraph sm">
                  <span>
                    <i className="fa fa-map-marker"></i>
                  </span>
                  <a target="_blank"
                      title="Linkedin"
                      rel="nofollow noopener noreferrer"
                      href="https://maps.app.goo.gl/K8G5S8sswpg4YgVe8">
                      Nebraska 62, Piso 3, Oficina 301, Col. Nápoles, Delg. Benito Juárez, 03810 Ciudad de México, CDMX.
                  </a>
                </p>
                <hr />
                <p className="box_p_foot paragraph sm">
                  <span>
                    <i className="fa-solid fa-phone"></i>
                  </span>
                  
                  <a href="tel:+5565865748">(556) 586 5748 </a>
                </p>
                <hr />
                <p className="box_p_foot paragraph sm">
                  <span>
                    <i className="fa-regular fa-envelope"></i>
                  </span>
                  <a href="mailto:contacto@jc-innovation.com">
                    contacto@jc-innovation.com
                  </a>
                </p>
                <hr />
                <div className="box_p_foot paragraph sm">
                    <span>
                      <i class="fa-solid fa-lock"></i>
                    </span>
                   <Link to="/aviso" >
                    <p  className="paragraph mb-0">{t('Aviso de Privacidad')}</p>
                  </Link>
               </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="drawer_foot_pie">
        <div className="container-lg">
          <div className="text-center">
            <small>
              Copyright ©{anioActual()}{t('JC-Innovation. Todos los Derechos Reservados.')} 
            </small>
          </div>
        </div>
      </div>
    </footer>
  );
};
