import React from "react";
import { useTranslation } from "react-i18next";

export const BtnDownload = (props) => {
  const {t,i18n}=useTranslation()

  const currentLanguage = i18n.language;

  const brochurePath = currentLanguage === 'es'
  ? '/JC-Innovation__ServiciosBrochure.pdf'
  :'/JC-Innovation__ServiciosBrochure_EN.pdf';
  
  return (
    <a
      className={` btn btn-brochure ${
        props.colors}`}
      download="JC Innovation - Brochure de Servicios"
      href={brochurePath}
    >
      <i className="fa-solid fa-download"></i>
      <span>{t('BROCHURE DE SERVICIOS')}</span>
    </a>
  );
};
